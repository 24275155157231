import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import { EventBus } from '@/main'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // Members Area Routes
  {
    path: '/members',
    component: () => import('@/views/Members/MembersMain.vue'),
    meta: {
      title: 'Member Portal | Progenics',
      requiresAuth: true
    },
    children: [
      {
        name: 'MembersHome',
        path: 'home',
        component: () => import('@/views/Members/MembersHome.vue'),
        meta: {
          title: 'Member Dashboard | Progenics'
        }
      },
      {
        name: 'EventAdmin',
        path: 'events',
        component: () => import('@/views/Members/Events.vue'),
        meta: {
          title: 'Event Management | Progenics'
        }
      },
      {
        name: 'Event-detail',
        path: 'events/detail/:id?',
        component: () => import('@/views/Members/EventDetail.vue'),
        meta: {
          title: 'Event Details | Progenics'
        }
      },
      {
        name: 'Event-detailView',
        path: 'events/view/:id?',
        component: () => import('@/views/Members/EventDetailView.vue'),
        meta: {
          title: 'View Event | Progenics'
        }
      },
      {
        name: 'BlogAdmin',
        path: 'blogs',
        component: () => import('@/views/Members/Blogs.vue'),
        meta: {
          title: 'Blog Management | Progenics'
        }
      },
      {
        name: 'Blog-detail',
        path: 'blogs/detail/:id?',
        component: () => import('@/views/Members/BlogDetail.vue'),
        meta: {
          title: 'Blog Details | Progenics'
        }
      },
      {
        name: 'Blog-detailView',
        path: 'blogs/view/:id?',
        component: () => import('@/views/Members/BlogDetailView.vue'),
        meta: {
          title: 'View Blog | Progenics'
        }
      },
      {
        name: 'ProfileAdmin',
        path: 'profiles',
        component: () => import('@/views/Members/Profiles.vue'),
        meta: {
          title: 'Profile Management | Progenics'
        }
      },
      {
        name: 'Profile-detail',
        path: 'profiles/detail/:id?',
        component: () => import('@/views/Members/ProfileDetail.vue'),
        meta: {
          title: 'Profile Details | Progenics'
        }
      },
      {
        name: 'Profile-detailView',
        path: 'profiles/view/:id?',
        component: () => import('@/views/Members/ProfileDetailView.vue'),
        meta: {
          title: 'View Profile | Progenics'
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      if (!store.getters.user || new Date() > store.getters.user.expirationTime) {
        next({
          name: 'Login',
          query: { returnUrl: to.path }
        })
      } else {
        next()
      }
    }
  },

  // Authentication Routes
  {
    path: '/login',
    component: () => import('@/views/login/LoginMain.vue'),
    meta: {
      title: 'Login | Progenics'
    },
    children: [
      {
        name: 'Login',
        path: '',
        component: () => import('@/views/login/Login.vue'),
        meta: {
          title: 'Login | Progenics'
        }
      },
      {
        name: 'LoginForgotPassword',
        path: 'forgot-password',
        component: () => import('@/views/login/LoginForgotPassword.vue'),
        meta: {
          title: 'Reset Password | Progenics'
        }
      },
      {
        name: 'LoginResetPassword',
        path: 'reset',
        component: () => import('@/views/login/LoginResetPassword.vue'),
        meta: {
          title: 'Set New Password | Progenics'
        }
      },
      {
        name: 'Register',
        path: '/register',
        component: () => import('@/views/login/Register.vue'),
        meta: {
          title: 'Create Account | Progenics'
        }
      }
    ]
  },

  // Main Public Routes
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('@/views/Main/Home.vue'),
        meta: {
          title: 'Progenics | Leading Stem Cell Bank'
        }
      },
      // About Us Section
      {
        name: 'AboutUs',
        path: 'about',
        component: () => import('@/views/Main/AboutUs.vue'),
        meta: {
          title: 'About Us | Progenics'
        }
      },
      {
        name: 'WhyProgenics',
        path: 'about/why-progenics',
        component: () => import('@/views/Main/AboutUs/WhyProgenics.vue'),
        meta: {
          title: 'Why Choose Progenics | Stem Cell Banking'
        }
      },
      {
        name: 'OurLab',
        path: 'about/our-lab',
        component: () => import('@/views/Main/AboutUs/OurLab.vue'),
        meta: {
          title: 'State-of-the-art Laboratory | Progenics'
        }
      },
      {
        name: 'OurClinic',
        path: 'about/our-clinic',
        component: () => import('@/views/Main/AboutUs/OurClinic.vue'),
        meta: {
          title: 'Our Clinic | Progenics'
        }
      },
      {
        name: 'OurTeam',
        path: 'about/our-team',
        component: () => import('@/views/Main/AboutUs/OurTeam.vue'),
        meta: {
          title: 'Expert Medical Team | Progenics'
        }
      },
      {
        name: 'Partnership',
        path: 'hospitals-we-serve',
        component: () => import('@/views/Main/AboutUs/Partnership.vue'),
        meta: {
          title: 'Partner Hospitals | Progenics'
        }
      },
      {
        name: 'Foundation',
        path: 'about/progenics-foundation',
        component: () => import('@/views/Main/AboutUs/Foundation.vue'),
        meta: {
          title: 'Progenics Foundation | Making a Difference'
        }
      },
      // Services Section
      {
        name: 'Services',
        path: 'services',
        component: () => import('@/views/Main/Services.vue'),
        meta: {
          title: 'Our Services | Progenics'
        }
      },
      {
        name: 'StemCellBanking',
        path: 'cord-blood',
        component: () => import('@/views/Main/Services/StemCellingBank.vue'),
        meta: {
          title: 'Cord Blood Banking | Progenics',
          description: 'Learn about our cord blood and tissue banking services'
        },
        alias: '/cord-tissue'
      },
      {
        name: 'VirtualConsultation',
        path: 'services/free-consultation',
        component: () => import('@/views/Main/Services/VirtualConsultation.vue'),
        meta: {
          title: 'Free Virtual Consultation | Progenics'
        }
      },

      // Science Section
      {
        name: 'Science',
        path: 'science',
        component: () => import('@/views/Main/Science.vue'),
        meta: {
          title: 'Stem Cell Science | Progenics'
        }
      },
      {
        name: 'WhyStore',
        path: 'why-store-cord-blood',
        component: () => import('@/views/Main/Science/WhyStore.vue'),
        meta: {
          title: 'Why Store Cord Blood | Progenics',
          description: 'Discover the importance of cord blood storage'
        }
      },
      {
        name: 'TreatableDiseases',
        path: 'treatable-diseases',
        component: () => import('@/views/Main/Science/TreatableDiseases.vue'),
        meta: {
          title: 'Treatable Diseases | Stem Cell Therapy',
          description: 'Learn about diseases treatable with stem cells'
        }
      },
      {
        name: 'Breakthroughs',
        path: 'science/breakthroughs',
        component: () => import('@/views/Main/Science/Breakthroughs.vue'),
        meta: {
          title: 'Recent Breakthroughs | Stem Cell Research'
        }
      },

      // How It Works Section
      {
        name: 'HowItWorks',
        path: 'how-it-works',
        component: () => import('@/views/Main/HowItWorks.vue'),
        meta: {
          title: 'How Cord Blood Banking Works | Progenics'
        }
      },
      {
        name: 'Procedures',
        path: 'unique-processing-method',
        component: () => import('@/views/Main/HowItWorks/Procedures.vue'),
        meta: {
          title: 'Our Unique Processing Method | Progenics',
          description: 'Learn about our advanced stem cell processing'
        }
      },
      {
        name: 'Delivery',
        path: 'how-it-works/delivery-day',
        component: () => import('@/views/Main/HowItWorks/Delivery.vue'),
        meta: {
          title: 'Delivery Day Process | Progenics'
        }
      },
      {
        name: 'CollectionKit',
        path: 'collection-kit',
        component: () => import('@/views/Main/HowItWorks/CollectionKit.vue'),
        meta: {
          title: 'Collection Kit Information | Progenics'
        }
      },
      {
        name: 'FAQ',
        path: 'faqs',
        component: () => import('@/views/Main/HowItWorks/FAQ.vue'),
        meta: {
          title: 'Frequently Asked Questions | Progenics',
          description: 'Find answers to common questions about cord blood banking'
        }
      },

      // Pricing and Contact
      {
        name: 'Pricing',
        path: 'pricing',
        component: () => import('@/views/Main/Pricing/CordBloodPricing.vue'),
        meta: {
          title: 'Cord Blood Banking Pricing | Progenics'
        },
        alias: ['/pricing-cordblood', '/pricing-cordbloodandcordtissue']
      },
      {
        name: 'Contact',
        path: 'contact',
        component: () => import('@/views/Main/Contact.vue'),
        meta: {
          title: 'Contact Us | Progenics'
        }
      },

      // Blog Section
      {
        name: 'Blogs',
        path: 'blogs',
        component: () => import('@/views/Main/Blog.vue'),
        meta: {
          title: 'Blog | Progenics',
          description: 'Latest news and updates in stem cell research'
        }
      },
      {
        name: 'Blog',
        path: 'blog/:id',
        component: () => import('@/views/Blogs/BlogTemplate.vue'),
        meta: {
          title: 'Blog Post | Progenics'
        }
      },
      {
        name: 'User',
        path: 'user/:id',
        component: () => import('@/views/Blogs/UserTemplate.vue'),
        meta: {
          title: 'User Profile | Progenics'
        }
      },

      // Enrollment
      {
        name: 'Enroll',
        path: 'enroll',
        component: () => import('@/views/Main/Enroll.vue'),
        meta: {
          title: 'Enroll Now | Progenics',
          description: 'Start your cord blood banking journey'
        }
      }
    ]
  },

  // 404 Route
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      title: 'Page Not Found | Progenics'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  }
})
// Navigation Guards
let initialRefresh = false

router.beforeEach(async (to, from, next) => {
  // Close any open navigation menu
  EventBus.$emit('closeNav')

  // Update page title
  document.title = to.meta?.title || 'Progenics'

  // Google Analytics tracking
  if ((window as any).gtag) {
    ;(window as any).gtag('config', 'G-G5QJ5ET1WN', {
      page_path: to.path,
      page_title: to.meta?.title
    })
  }

  // JWT token refresh logic
  if (!initialRefresh) {
    initialRefresh = true

    try {
      await store.dispatch('refreshAccessToken')
      next()
    } catch (error) {
      console.error('Failed to refresh token:', error)
      if (to.meta?.requiresAuth) {
        next({ 
          name: 'Login',
          query: { returnUrl: to.fullPath }
        })
      } else {
        next()
      }
    } finally {
      store.dispatch('setJwtSilentRefresh')
    }
    return
  }

  // Handle meta requirements
  if (to.meta?.requiresAuth) {
    const isAuthenticated = store.getters.isAuthenticated
    if (!isAuthenticated) {
      next({
        name: 'Login',
        query: { returnUrl: to.fullPath }
      })
      return
    }
  }

  next()
})

// Update meta tags for SEO
router.afterEach((to) => {
  // Update meta description
  const description = to.meta?.description
  let metaDescription = document.querySelector('meta[name="description"]')
  
  if (!metaDescription) {
    metaDescription = document.createElement('meta')
    metaDescription.setAttribute('name', 'description')
    document.head.appendChild(metaDescription)
  }
  
  metaDescription.setAttribute(
    'content',
    description || 'Progenics - Leading provider of cord blood and tissue banking services'
  )
})

export default router